.event-summary{
  &-box{
    display: flex;
    color:color(primary-color);
    align-items: baseline;
    padding:10px;
    @include box-shadow(0px,0px,4px,rgba(color(blackColor),0.15));
    margin-bottom: 15px;
    background: color(whitColor);
    h6{
      font-size: 14px;
      font-weight: bold;
      color:color(primary-color);
      margin:0 10px;
    }
    span.data{
      color:color(blackColor);
      font-size: 14px;
    }
  &.details{
    flex-direction: column;
    h6{
      margin: 0 0 6px;
    }
   }
  }

  
}
.dashboard-tabs{
  margin-top: 50px;
  &-nav{
    display: flex;
    .nav-link{
      color:color(blackColor);
      background-color: shadecolor(greyColor,light);
      padding:0px 12px;
      min-width: 100px;
      text-align: center;
      margin-right: 10px;
      font-size: 14px;
      &.active,&:hover{
        color:color(whitColor);
        background-color: color(primary-color);
      }
    }
  }
  .tab-content{
    clear: both;
    .tab-pane{
      padding:30px 30px;
      @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.2));
      background: color(whitColor);
    }
  }
  
}

.event-table{

  .react-bootstrap-table{
    max-width: 100%;
    overflow-x: auto;
    .table{
      
      thead{
        color:color(whitColor);
        background-color: shadecolor(greyColor,grey-500);
        & > tr:last-child{
          & > td{
            padding: 5px;
            background-color: shadecolor(greyColor,dark);
            color:color(whitColor);
          }
        }
      }
      .innerHeading{
        text-align:center;
        td{
          padding: 5px;
          border:none;
          font-size: 14px;
          &:not(:last-child){
           border-right: 1px solid color(whitColor);
          }
        }
      }
      .double-conent{
        width: 100%;
        td{
           border:none;
          font-size: 14px;
        }
      }
    }
  }
  table{
    thead{
      tr{
       th{
        .filter{
          background: #767676;
          color: #fff;
          padding: 2px 6px !important;
          font-size: 0.8rem;
          height: 30px;
          border-radius: 4px;
        }
        .form-control::placeholder,.form-control::-moz-placeholder,.form-control::-ms-input-placeholder,.form-control::-ms-input-placeholder{
          color: #ffffff !important;
          opacity: 1; /* Firefox */
        }
        input{
          border-radius: 4px !important;
        }
        select{
          option{
            color: #fff !important;
          }
        }
      }
    }
  }
  .form-control::placeholder{
    color: #ffffff !important;
  }
  }
}
.event-table-top{
  margin:0px 0 30px;
  .request-outer{
    display: flex;
    margin-top: 15px;
  }
  .request{
    display: flex;
    align-items: center;
    margin-right: 30px;
    span{
      width: 20px;
      height: 20px;
      @include border-radius(6px);
      background-color: color(primary-color);
      display: inline-block;
      margin-right: 10px;
      &.multiple{
        background-color: color(blue-color);
      }
    }
  }
  .rt-btn{
    float: right;
  }
}

.tickets-summary{
  background-color: color(blue-color);
  padding: 20px;
  margin-bottom: 15px;
  p{
    color:color(whitColor);
    font-size: 15px;
    margin:5px 0 0 0;
  }
}

.event-popup{
  .modal-header{
    background-color: color(blue-color);
    color:color(whitColor);
    button{
      color:color(whitColor);
      opacity: 1;
    }
  }
}

.section-icon{
  width: 50px;
  height: 50px;
  background: color(whitColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: color(primary-color);
  @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.2));
  position: absolute;
  left:0;
  padding: 8px;
}

.innerHeading{
  &>tr{
    &>td{
      text-align: center;
      padding:0 3px !important;
      font-size: 12px !important; 
      &:not(:last-child){
	    text-align: center !important;
        border-right: 1px solid #dee2e6 !important;
      }
    }
  }
}

.double-conent{
  tbody{
    td{
      text-align: center;
      padding:0 5px;
	  width: 50px
    }
  }
}

.action-buttons{
  .btn{
    font-size: 12px;
    padding: 5px 7px;
    min-height: 25px;
    line-height: 16px;
      margin-bottom: 3px !important;
    & + .btn{
      margin-left: 3px;
    }
  }
}

.popover{
  &-header{
    background-color: color(whitColor);
    border: none;
  }
  .pop-content{
    margin-bottom: 20px;
    h6{
      font-size:13px;
      color: color(blue-color);
      font-weight: bold;
      margin-bottom: 5px;
    }
    p{
      background: shadecolor(greyColor,light);
      padding: 3px;
      margin: 0 0 5px 0;
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.info-span {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: shadecolor(greyColor,dark);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  margin-left: 5px;
  font-weight: 300;
}

.graph-labels{
  margin-top: 20px;
  li{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    .tile{
      width:50px;
      height: 35px;
     
      margin-right: 9px;
      @include border-radius(6px);
      background: shadecolor(greyColor,grey-900);
    }
    &.blue{
      .tile{
        background: #007bff;
      }
    }
    &.orange{
      .tile{
        background: #fd7e14;
      }
    }
    &.green{
      .tile{
        background: #28a745;
      }
    }
    &.purple{
      .tile{
        background: #6f42c1;
      }
    }
    &.red{
      .tile{
        background: #dc3545;
      }
    }
  }
}

.graph-outer{
  max-width: 100%;
  overflow-x: auto;
}

.client-modal-content p {
    background: #e8e8e8;
    padding: 3px;
    margin: 0 0 7px 0;
    font-weight: 600;
    font-size: 14px;
}

.client-modal-content h6 {
    font-size: 16px;
    color: #046db5;
    font-weight: bold;
    margin-bottom: 7px;
}
tbody.client-tbody>.multiple_req_color> td:first-child{
color: #046db5;
}
tbody.client-tbody>.single_req_color> td:first-child {
color: #689729;
}
