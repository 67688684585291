

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius; 
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin input($direction,$width,$style,$color) {
    border:none;
    border-radius: 0;
    box-shadow: none;
    padding-left: 2px;
    background-color: color(whiteColor);
    border-#{$direction}:$width $style $color;   
}