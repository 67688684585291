footer{
  background: color(black-300);
 
  padding:20px 0;
  
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      &.copyright{
        font-size: 0.8rem;
        color: color(whitColor);
      }
    }
    ul{
      display: flex;
      li{
        margin:0 10px;
        span{
          color: color(whitColor);
        }
      }
    }
  }
}