@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    list-style-type: none;
  }
}
.user-dashboard{
  min-height: 100vh;
  background-color: shadecolor(greyColor,light-grey) ;
}

.error{
  color: color(redColor);
  font-size: 13px;
}

.loader_div {
  display   : block;
  width     : 100%;
  height    : 100%;
  background: #fff;
  position  : fixed;
  z-index   : 1125;
  opacity   : 0.6;
  left      : 0;

  .inner-loader {
    position     : absolute;
    top          : 50%;
    left         : 50%;
    /* background: #fff; */
    opacity      : 1;
    z-index      : 1100;
  }
}

.no-padding {
padding: 0px
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

input, button, select, optgroup, textarea{
  font-family: 'Montserrat', sans-serif;
}
body{
  font-family: 'Montserrat', sans-serif;
}
